.site-button {
    width: 230px;

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
            height: 26px;
            margin-right: 11px;
        }
        & > svg {
            height: 26px;
            margin-right: 11px;
        }
    }

    .ant-btn-loading-icon {
        display: none;
    }
}

.site-button.uppercase{
    text-transform: uppercase;
}

.site-button.full-width{
    width: 100% !important;
}

.linkwithus-button-primary.occurrence-button, 
.occurrence-button.linkwithus-button-primary:not([disabled]):hover, 
.occurrence-button.linkwithus-button-primary:focus {
    background-color: #C3352D;
    border-color: #C3352D;
    color: #fff;
}

.occurrence-button.linkwithus-button-primary:not([disabled]):hover {
    box-shadow: none;
}

.linkwithus-button.occurrence-button {
    height: 45px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 27px;
}

.linkwithus-button-primary.occurrence-button.grey, 
.occurrence-button.grey.linkwithus-button-primary:not([disabled]):hover, 
.occurrence-button.grey.linkwithus-button-primary:focus {
    background-color: transparent;
    border-color: #9D9D9D;
    color: #9D9D9D;
}

.occurrence-button.grey.linkwithus-button-primary:not([disabled]):hover {
    box-shadow: none;
}

.linkwithus-button-primary.occurrence-button.grey, 
.occurrence-button.grey.linkwithus-button-primary:not([disabled]):hover, 
.occurrence-button.grey.linkwithus-button-primary:focus {
    background-color: transparent;
    border-color: #9D9D9D;
    color: #9D9D9D;
}

.occurrence-button.grey.linkwithus-button-primary:not([disabled]):hover {
    box-shadow: none;
}