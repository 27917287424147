.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

html {
    font-family: 'Nunito', sans-serif;
}

html body {
    font-family: 'Nunito', sans-serif;
}

html p {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

div .linkwithus-phone-input .ant-input:hover, .linkwithus-phone-input .ant-input:focus {
    border-color: #E3E3E3 !important;
}

div .linkwithus-input.ant-input-affix-wrapper-focused, .linkwithus-input:hover, .linkwithus-input:focus {
    border-color: #E3E3E3 !important;
}

div .linkwithus-input:not(textarea) {
    border-radius: 7px;
    border-color: #E3E3E3;
    box-shadow: none;
    padding: 8px 15px 8px;
    height: 40px !important;
    color: #272727;
}

div .linkwithus-input {
    border-radius: 7px;
    border-color: #E3E3E3;
    box-shadow: none;
    padding: 8px 15px 8px;
    color: #272727;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9D9D9D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  
input:-ms-input-placeholder, input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9D9D9D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
  
input::-ms-input-placeholder { /* Microsoft Edge */
    color: #9D9D9D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

div .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: #9D9D9D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

div .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #DDDDDD;
    border-right-width: 1px;
    z-index: 1;
}

div .filter-table__footer__left-column__dropdown .linkwithus-dropdown .ant-select-selector input {
    height: 36px !important;
}

div .filter-table__footer__left-column__dropdown div.linkwithus-dropdown.ant-select .ant-select-selector {
    padding-top: 0px;
    height: 36px !important;
}

div .filter-table__footer__left-column__dropdown .linkwithus-dropdown.ant-select.ant-select-single .ant-select-selection-item, .linkwithus-dropdown .ant-select-selection-placeholder {
    line-height: 36px !important;
    margin-top: -2px !important;
}

div .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #DDDDDD;
    border-right-width: 1px;
}

div .linkwithus-dropdown.ant-select .ant-select-arrow {
    margin-top: -6px !important;
}

div:not(.president-text-area) > .linkwithus-text-area.linkwithus-input {
    height: 100px !important;
}

div .linkwithus-input.linkwithus-phone-input {
    padding: 0px;
}

.linkwithus-dropdown-popup .ant-select-item.ant-select-item-option-selected {
    background-color: #C3352D!important;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg {
    background: #C3352D;
    border-radius: 100%;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg g path:nth-child(2){
    stroke: #FFFFFF;
    fill: #FFFFFF;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg g path:nth-child(3){
    stroke: #FFFFFF;
    fill: #FFFFFF;
}

/*
START- SCROLL-BAR style
*/
/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    /*border-radius: 10px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #C3352D;
    /*border-radius: 10px;*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #C3352D; 
}
/*
END - SCROLL-BAR style
*/

div.linkwithus-modal div.ant-modal-body {
    padding: 0px;
    max-height: 90vh;
}

div i.ant-spin-dot-item{
    background-color: #C3352D;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
