.occurrence-carousel{
    position: relative;
    overflow-x: hidden;

    .reverse{
        transform: rotate(180deg);
    }

    &__button:hover{
        cursor:pointer;
    }

    &__button{
        border:none;
        margin: 0px;
        padding:0px;
        position: absolute;
        top: 43px;
        width: 36px;
        height: 36px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        z-index: 1;
        opacity: 0.7;

        & > svg{
            path{
                fill: black;
            }
        }
    }

    &__button.left{
        left:20px;
    }

    &__button.right{
        right: 20px;
    }

    &__translater{
        width: fit-content;
        overflow: hidden;
        display: flex;
        transition: transform 1s ease;
        gap: 12px;

        &__container{
            margin: 0px;
            padding: 0px;
            border: none;
            background-color: transparent;

            &__element{
                background-size: cover;
                background-position: center;
                width: 195px;
                height: 124px;
            }
        }

        &__container:hover{
            cursor: pointer;
        }
    }
}