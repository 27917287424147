.current-assembly-president{
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__empty{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0px;
    }

    &__header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-bottom: 20px;
    }

    &__content{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

        &__entity{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-width: 250px;

            & > img{
                width: 168px;
                height: 168px;
                object-fit: cover;
                border-radius: 50%;
            }
    
            &__title{
                line-height: 20px;
                margin: 20px 0px;
                font-weight: 800;
                font-size: 18px;
                text-align: center;
                padding: 0px 20px;
                & > button{
                    margin: 0px 10px;
                    padding: 0px;
                    width: 25px;
                    border: none;
                    border-radius: 7px;
                    height: 25px;
                    font-size: 16px;
                    font-weight: bold;
                    background-color: #FFFFFF;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                    & > img{
                        width: 15px;
                        height: 15px;
                    }
                }
    
                & > button:hover{
                    cursor: pointer;
                }
            }
        }
    
        & p{
            color: #000000;
        }
    
        &__description{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 40px;
            &__content{
                line-height: 25px;
                font-weight: bold;
                font-size: 14px;
                color: #000000;

                
            & > button{
                margin: 0px 10px;
                padding: 0px;
                width: 25px;
                border: none;
                border-radius: 7px;
                height: 25px;
                font-size: 16px;
                font-weight: bold;
                background-color: #FFFFFF;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                & > img{
                    width: 15px;
                    height: 15px;
                }
            }

            & > button:hover{
                cursor: pointer;
            }
            }

            & > button{
                padding: 0px;
                width: 100%;
                border: none;
                border-radius: 7px;
                height: 40px;
                font-size: 16px;
                font-weight: bold;
                background-color: #E8E8E8;
                color: #7C7C7C;
            }

            & > button:hover{
                cursor: pointer;
            }
        }
    }
}