.forgot-password-page{
    height: fit-content;
    min-height: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    padding: 0px 12%;

    &__header{
        padding-top: 5%;
        margin-bottom: 20px;

        .spacer{
            margin-top: 22px;
        }

        .uppercase{
            text-transform: uppercase;
        }

        & img{
            width: 130px;
            height: 157px;
            display: block;
            margin: auto;
        }

        & p{
            color:#000000;
            line-height: 27px;
            text-align: center;
        }

        &__medium{
            font-size: 20px;
        }

        &__bold{
            font-size: 24px;
            font-weight: bold;
        }
    }

    &__form{
        margin-bottom: 5%;
        width: 100%;

        &__step1-button{
            margin-top: 20px;
        }

        &__step2-button{
            margin-top: 60px;
        }

        &__label{
            font-size: 14px;
            line-height: 27px;
            color: #000000;
            text-align: left;
            padding-left: 2px;
        }

        &__question{
            font-size: 18px;
            font-weight: bold;
            line-height: 27px;
            color: #000000;
            text-align: center;
            margin-bottom: 20px;
        }

        &__email{
            font-size: 18px;
            font-weight: bold;
            line-height: 27px;
            color: #000000;
            text-align: center;
            margin-bottom: 30px;
        }

        &__instruction{
            font-size: 14px;
            line-height: 27px;
            color: #000000;
            text-align: center;
            margin-bottom: 30px;
        }

        &__instruction-step2{
            font-size: 14px;
            line-height: 27px;
            color: #000000;
            text-align: center;
            margin-bottom: 20px;
        }

        &__field{
            margin-bottom: 40px;
        }

        &__field.half-margin{
            margin-bottom: 20px;
        }

        &__remember-section{
            display: flex;
            margin-bottom: 70px;
            align-items: center;
            font-size: 12px;
            justify-content: space-between;
            p, &__link {
                margin-top: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            a {
                text-decoration: underline;
                font-weight: bold;
                color: #C3352D;
            }
    
            a:hover{
                text-decoration: none;
                color: #C3352D;
            }
        }
    }
}