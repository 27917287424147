.edit-account-access-modal{

    .ant-modal-body {
        padding: 0px;
        max-height: 90vh;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px grey;*/
        background: #e8e8e8;
        /*border-radius: 10px;*/
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #C3352D;
        /*border-radius: 10px;*/
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #C3352D;
    }

    &__content{
        div .ant-input[disabled] {
            color:#000000;
        }

        &__invite-info{
            margin:0px 20px;

            & > p {
                margin: 20px 0px 5px 0px;
                font-size: 16px;
                color: #1F1E1F;
                font-weight: bold;
                line-height: 22px;
            }

            & > button{
                margin: 25px 0px;
                padding: 0px;
                width: 100%;
                border: none;
                border-radius: 7px;
                height: 40px;
                font-size: 16px;
                font-weight: bold;
                background-color: #E8E8E8;
                color: #7C7C7C;
            }

            & > button:hover{
                cursor: pointer;
            }
        }

        &__text{
            font-size: 20px;
            color: #1F1E1F;
            font-weight: bold;
            line-height: 22px;
            padding:50px 70px 10px 70px;
            text-align: center;
        }

        &__footer{
            margin-top: 20px;
            padding: 20px;
            gap:10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            &__button{
                width: auto;
            }
        }
    }
}