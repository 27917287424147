.pin-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 68px 0px 68px;
    text-align: center;

    &__number-box {
        margin-right: 27px;
        border-bottom: 3px solid #e3e3e3;
        width: 31px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__element .linkwithus-input::placeholder {
            opacity: 0;
        }

        &__element,
        &__element::placeholder,
        &__element:focus,
        &__element:focus-visible {
            color: #272727;
            font-family: 'Nunito', sans-serif;
            font-size: 37px;
            font-weight: 800;
            margin-bottom: 0px;
            padding: 0px;
            padding-left: 4px;
            border: none;
            box-shadow: none;
            width: 100%;
            outline: 0;
        }
    }

    &__number-box.filled {
        border-bottom: 3px solid black;
    }
    
    &__number-box.last_box {
        margin-right: 0px;
    }
}
