.occurrence-side-bar{
    z-index: 1000;
    width: 0px;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 100px;
    color:#000000;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #C3352D;
        border-radius: 10px;
    }

    &__content{
        position: relative;
        width: 0px;
        height: 100vh;
        overflow-y: scroll;
        background-color: #FFFFFF;
        z-index: 2;
        transition: all 0.2s ease-in-out;

        & > div.active{
            opacity: 1;
        }

        & > div{
            opacity: 0;
        }

        & > p.active{
            opacity: 1;
        }

        & > p{
            opacity: 0;
        }

        &__header{
            padding: 80px 20px 50px 20px;
            margin: 0px 20px 35px 20px;
            border-bottom: 1px solid #EFEFEF;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            &__text{

                &__title{
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 5px;
                }

                &__subtitle{
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            & > button{
                border:none;
                border-radius: 7px;
                background-color: #EFEFEF;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                & > img{
                    transform: rotate(180deg);
                }
            }

            & > button:hover{
                cursor: pointer;
            }
        }

        &__carousel{
            height: 124px;
        }

        &__block-title{
            font-weight: bold;
            font-size: 14px;
            text-align: left;
            margin-top:30px;
            margin-bottom: 14px;
            padding: 0px 26px;
        }

        &__block.last{
            border: none;
            max-height: 400px;
            overflow: auto;
            display: flex;
            align-items: flex-start;
        }

        &__block{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px 26px;
            padding: 0px 0px 20px 0px;
            border-bottom: 1px solid #EFEFEF;
            color: #9D9D9D;
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;

            .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border-color: #E3E3E3 !important;
            }

            &__state-dropdown{
                .linkwithus-dropdown {
                    font-size: 14px;
                    width: 100%;

                    .ant-select-selector {
                        border-radius: 40px;
                        padding: 0 11px;
                    }

                    .ant-select-selection-placeholder {
                        line-height: 36px !important;
                    }
                }
            }

            &__state{
                padding: 6px 15px;
                font-weight: 600;
                font-size: 14px;
                border-radius: 40px;
            }

            &__state.pending{
                color: #CEB500;
                background-color: #F4E57B;
            }

            &__state.concluded{
                background-color: #B6E388;
                color: #78C32C;
            }

            &__state-button{
                color:#9D9D9D;
                margin: 0;
                padding: 0;
                border:none;
                background-color: transparent;
                text-decoration: underline;
            }

            &__state-button.editing{
                color: #C3352D;
            }
            
            &__state-button:hover{
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

    &__content.active{
        width: 500px;
    }

    &__background{
        border:none;
        padding: 0px;
        margin: 0px;
        position: fixed;
        top: 0px;
        left: 100px;
        width: 0%;
        height: 100%;
        z-index: 1;
        opacity: 0.5;
        background-color: #000000;
    }

    &__background.active{
        width: 100%;
    }
}

.occurrence-side-bar.active{
    width: 100vw;
}