.settings-page{
    position: relative;
    color: #000000;

    &__section{
        border-top: 1px solid #DDDDDD;
        padding: 45px 10%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
        flex-wrap: wrap;

        &__title{
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
        }

        &__right-column{
            width: 568px;

            &__label{
                font-size: 14px;
                padding-left: 2px;
            }

            &__avatar{
                width: 168px;
                height: 168px;
                object-fit: cover;
                border-radius: 50%;
            }

            &__field{
                margin-bottom: 33px;
            }

            &__button-container{
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                &__button{
                    width: 150px;
                }
            }
        }
    }
}