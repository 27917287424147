.arquived-modal{

    &__content{
        padding: 87px 70px 60px 70px;

        & > img{
            display: block;
            margin: auto;
        }

        & > p{
            margin-top: 27px;
            line-height: 27px;
            font-size: 20px;
            color: #000000;
            font-weight: 800;
            text-align: center;
        }
    }
}