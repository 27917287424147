.summary{
    border-right: 1px solid #DDDDDD;
    padding-right: 40px;
    padding-right: 4vw;

    &__title{
        font-size: 19px;
        line-height: 26px;
        color: #9D9D9D;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &__values{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__current{
            font-size: 37px;
            color: #11193C;
            line-height: 50px;
            font-weight: 600;
            margin-right: 20px;
        }

        &__percentage{
            font-size: 19px;
            line-height: 26px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & > img{
                margin-right: 10px;
            }
        }

        &__percentage.increase{
            color: #00E38C;
        }

        &__percentage.decrease{
            color: #FF4B75;
        }
    }
}