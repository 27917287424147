.file-uploader {
    position: relative;
    width: fit-content;

    &__avatar{
        width: 168px;
        height: 168px;
        object-fit: cover;
        border-radius: 50%;
    }

    & > span {
        .ant-upload.ant-upload-drag,
        .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
            width: 168px;
            height: 168px;
            border-radius: 50%;
            border: 1px dashed #9D9D9D;
            background-color: #FAFAFA;
            padding: 0px;
        }
    }

    &__dragger.ant-upload.ant-upload-drag {
        border-radius: 7px;
        padding-top: 24px;
    }

    .ant-upload-drag-container{
        width: 168px;
        height: 168px;
        border-radius: 50%;
    }

    &__dragger {
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__header {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000000;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }

        &__description{
            text-align: center;
            margin-top: 8px;
            font-size: 12px;
            line-height: 27px;
            color: #0A1638;
        }
    }

    &__content:hover{
        cursor: pointer;
    }

    &__content {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #00000029;

        &__list {
            width: 100%;

            &__item {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                background-color: #FFFFFF;
                font-size: 14px;
                color: #000000;
                position: relative;

                &__remove{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__header{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    &__icon{
                        background-color: #DDDDDD;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        
                        img{
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                & > div{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & > div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &__size {
                    margin: 0px 10px;
                    padding-top: 2px;
                    
                    & > div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &__remove {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease-in-out;
                    position: relative;

                    & > img {
                        width: 20px;
                        height: 20px;
                        opacity: 1;
                    }

                    & > img:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0px !important;
}

.rectangular .file-uploader{

    &__avatar {
        width: 210px;
        height: 140px;
        object-fit: cover;
        border-radius: inherit;
    }

    &__content {
        bottom: -10px;
        right: -10px;
        box-shadow: 0px 3px 6px #00000029;
    }

    & > span {
        .ant-upload.ant-upload-drag,
        .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
            width: 210px;
            height: 140px;
            border-radius: inherit;
            border: 1px dashed #9D9D9D;
            background-color: #FAFAFA;
            padding: 0px;
        }
    }
}
