.confirmation-modal{

    &__content{

        &__text{
            font-size: 14px;
            color: #9D9D9D;
            line-height: 19px;
            padding:15px;
        }

        &__footer{
            border-top: 1px solid #E0E0E0;
            padding: 10px;
            gap:10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            &__button{
                width: auto;
            }
        }
    }
}