.background{
    min-height: 100vh;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    /*
    START- SCROLL-BAR style
    */
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #C3352D;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #C3352D; 
    }
    /*
    END - SCROLL-BAR style
    */

    &__page-container{
        width: 576px;
        max-height: 767px;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        background-color: #FFFFFF;
        border-radius: 7px;
        z-index: 2;

        @media only screen and (max-width: 576px) {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
    }

    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        opacity: 1;
    }
}