.visibility-button{
    width: 50px;
    height: 26px;
    border-radius: 13px;
    border: none;
    margin: 0px;
    background-color: #DDDDDD;
    transition: all 0.2s ease-in-out;
    position: relative;

    &__content{
        position: absolute;
        top: 2px;
        left: 2px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #FFFFFF;
        transition: all 0.2s ease-in-out;
    }
}

.visibility-button:hover{
    cursor: pointer;
}

.active.visibility-button{
    background-color: #E4F5D3;

    &__content.active{
        background-color: #78C32C;
        left: calc(100% - 24px);
    }
}