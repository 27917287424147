.county-page{
    border-radius: 7px;
    border: 1px solid #DDDDDD;
    position: relative;

    &__header{
        height: 70px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__filler{
            height: 100%;
            width: 100%;
            border-bottom: 1px solid #DDDDDD;
        }

        &__tab:hover{
            cursor: pointer;
            color: #000000;
        }

        &__tab.active{
            border-bottom: none;
            color: #000000;
        }

        &__tab{
            height: 100%;
            padding: 0px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-right: 1px solid #DDDDDD;
            border-bottom: 1px solid #DDDDDD;
            background-color: transparent;
            color: #9D9D9D;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            min-width: fit-content;
        }
    }

    &__container{
        padding: 40px;
    }
}