.invite-users-modal{

    .ant-modal-body {
        padding: 0px;
        max-height: 90vh;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px grey;*/
        background: #e8e8e8;
        /*border-radius: 10px;*/
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #C3352D;
        /*border-radius: 10px;*/
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #C3352D;
    }

    &__content{
        &__invite-info{
            margin:0px 20px;

            & > p {
                margin: 20px 0px 5px 0px;
                font-size: 16px;
                color: #1F1E1F;
                font-weight: bold;
                line-height: 22px;
            }

            & > button{
                margin: 25px 0px;
                padding: 0px;
                width: 100%;
                border: none;
                border-radius: 7px;
                height: 40px;
                font-size: 16px;
                font-weight: bold;
                background-color: #E8E8E8;
                color: #7C7C7C;
            }

            & > button:hover{
                cursor: pointer;
            }
        }

        &__text{
            font-size: 20px;
            color: #1F1E1F;
            font-weight: bold;
            line-height: 22px;
            padding:50px 70px 10px 70px;
            text-align: center;
        }

        &__invite-list-title {
            margin: 0px 20px 5px 20px;
            font-size: 16px;
            color: #1F1E1F;
            font-weight: bold;
            line-height: 22px;
        }

        &__invite-list{
            border-top: 1px solid #E0E0E0;
            padding: 0px 20px 10px 20px;
            font-size: 14px;
            line-height: 22px;
            color: #707070;
            max-height: 160px;
            min-height: 84px;
            overflow-y: auto;
    
            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;
                padding: 15px 0px;
                margin: 0px 10px;
                &__info {
                    color: #7c7c7c;
                    &__email {
                        font-weight: bold;
                        font-size: 16px;
                    }
                    &__type {
                        font-size: 14px;
                    }
                    &__email,
                    &__type {
                        max-width: 338px;
                        margin: 0;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                &__button {
                    border-radius: 50%;
                    border: none;
                    width: 20px;
                    height: 20px;
                    background-color: #e8e8e8;
                    color: #7c7c7c;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    & > p {
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
                &__button:hover {
                    cursor: pointer;
                }
            }
            &__item.border {
                border-bottom: 1px solid #e0e0e0;
            }
        }

        &__footer{
            border-top: 1px solid #E0E0E0;
            padding: 10px;
            gap:10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            &__button{
                width: auto;
            }
        }
    }
}