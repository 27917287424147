.content-settings-order-button{

    &__content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        border: none;
        padding: 0px 10px 0px 60px;
        margin: 0px;
        background-color: transparent;
        text-transform: uppercase;

        p{
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            color: #9D9D9D;
        }

        p.active{
            color: #000000;
        }

        & > img{
            transition: all 0.3s ease-in-out;
            margin-top: -2px;
        }

        & > img.desc{
            transform: rotate(90deg);
        }

        & > img.asc{
            transform: rotate(-90deg);
        }
    }

    button:hover{
        cursor: pointer;
    }
}