.gastronomy-filter-table-item{
    height: 80px;
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    color: #000000;
    font-size: 14px;
    position: relative;

    &__clicable{
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;

        &__left-field{
            width: calc(100% / 3);
            max-width: 325px;

            &__img{

                & > img{
                    width: 85px;
                    height: 57px;
                    object-fit: cover;
                }
            }
        }

        &__right-field{
            width: calc(100% / 3);
            gap: 20px;
            justify-content: space-between;

            &__edit-button, &__delete-button{
                position: absolute;
                background-color: transparent;
                border: none;
                padding: 0;
            }

            &__edit-button{
                right: 100px;
            }

            &__delete-button{
                right: 45px;
            }

            &__edit-button:hover, &__delete-button:hover{
                cursor: pointer;
            }
        }

        &__left-field, &__right-field{
            padding-left: 60px;
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;
    
            p{
                line-height: 14px;
                display: inline-block;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-right: 20px;
                max-width: 300px;
            }

            @media only screen and (max-width: 1500px) {
                p{
                    max-width: 200px;
                }
            }

            @media only screen and (max-width: 1400px) {
                p{
                    max-width: 100px;
                }
            }
        }
    }
}