.tab{
    width: 100%;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 24px 0px;

    & svg{
        height: 27px;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    &__marker{
        width: 8px;
        height: 100%;
        position: absolute;
        right: -4px;
        top: 0px;
        border-radius: 10px;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
    }

    &__marker.active{
        background-color: #FFFFFF;
    }
}

.tab.active{

    & svg{
        opacity: 1;
    }
}