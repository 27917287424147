.menu{
    display: flex;
    position: relative;

    &__content{
        width: 100px;
        height: 100vh;
        background-color: #C3352D;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        &__tabs{
            margin-top: 35px;
            width: 100%;
            overflow: hidden;

            & button{
                padding: 0px;
                display: block;
                margin: auto;
                background-color: transparent;
                border: none;

                & > img{
                    width: 58px;
                    height: 70px;
                }
            }

            & button:hover{
                cursor: pointer;
            }
        }
    }

    &__page-container{
        width: calc(100% - 100px);
        height: 100vh;
        overflow: auto;
    }
}