.event-modal{

    .ant-modal-body{
        max-height: fit-content !important;
    }

    &__content{

        &__title{
            margin: 63px 0px 52px 0px;
            text-align: center;
            padding: 0px 20px;
            text-transform: uppercase;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
        }

        &__body{
            margin: 0px 20px 50px 20px;

            &__label{
                font-size: 14px;
                line-height: 27px;
                color: #000000;
                padding-left: 2px;
            }

            &__field{
                margin-bottom: 32px;
            }
        }

        &__footer{
            border-top: 1px solid #E0E0E0;
            padding: 10px;
            gap:10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            &__button{
                width: auto;
            }
        }
    }
}