.filter-table-item{
    height: 80px;
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    color: #000000;
    font-size: 14px;
    position: relative;

    &__clicable:hover{
        cursor: pointer;
    }

    &__clicable{
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;


        &__field{
            width: calc(100% / 6);
            padding-left: 30px;
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;

            &__user{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
    
            &__state{
                padding: 5px 15px;
                border-radius: 40px;
                font-weight: 600;
                font-size: 14px;
            }
    
            &__state.pending, &__state.concluded{
                line-height: 20px;
            }
    
            &__state.pending{
                background-color: #F4E57B;
                color: #CEB500;
            }
    
            &__state.concluded{
                background-color: #B6E388;
                color: #78C32C;
            }
        
    
            p{
                line-height: 14px;
                display: inline-block;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-right: 20px;
                max-width: 300px;
    
                & > img{
                    margin-right: 6px;
                    margin-top: -1px;
                }
            }

            @media only screen and (max-width: 1500px) {
                p{
                    max-width: 200px;
                }
            }

            @media only screen and (max-width: 1400px) {
                p{
                    max-width: 100px;
                }
            }
        }
    }

    &__selector{
        width: 80px;
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;

        span.ant-checkbox{
            margin-left: 18px;
        }
    }
}