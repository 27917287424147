.page-content-container{
    padding:9vh 5vw 40px 5vw;

    &__title, &__sub-title{
        color: #000000;
    }

    &__title{
        font-weight: bold;
        font-size: 32px;
    }

    &__sub-title{
        font-size: 16px;
        line-height: 8px;
    }

    &__page{
        margin-top: 6vh;
    }

    &__page.greet-user{
        margin-top: calc(6vh - 8px);
    }
}