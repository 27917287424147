.loading-page {

    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C3352D;

    &__content{

        & > img{
            display: block;
            margin: auto;
        }

        &__logo{
            width: 130px;
            height: 157px;
        }

        &__text{
            margin: 32px 0px 20px 0px;

            & > p{
                text-align: center;
                color: #FFFFFF;
                line-height: 27px;
            }

            &__regular{
                font-size: 20px;
            }

            &__bold{
                font-size: 24px;
                font-weight: bold;
            }

            .uppercase{
                text-transform: uppercase;
            }
        }

        &__loading{
            opacity: 0.5;
            animation: rotation 1.5s infinite ease-in-out;
        }
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
