.rent-a-car-filter-table{
    width: 100%;
    min-width: fit-content;
    background-color: #FFFFFF;
    border-radius: 7px;
    border: 1px solid #DDDDDD;
    font-size: 14px;
    color: #9D9D9D;
    font-weight: 600;

    &__header, &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
    }

    &__header{
        padding: 15px 20px;
        border-bottom: 1px solid #DDDDDD;

        &__left-column, &__right-column{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        &__left-column{

            &__dropdown{
                width: 208px;
            }

            &__search{
                width: 400px;
            }

            &__button{
                height: 40px;
                padding: 0px 15px;
                border-radius: 7px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #C3352D;
                color:#FFFFFF;
                font-weight: 600;
                line-height: 14px;
            }

            &__button:hover{
                cursor: pointer;
            }
        }

        &__right-column{

            & > button{
                height: 40px;
                border-radius: 7px;
                border: 1px solid #DDDDDD;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #FFFFFF;
                font-weight: 600;
                line-height: 14px;
                padding: 0px 15px;

                & > img{
                    margin-right: 7px;
                }
            }

            & > button:hover{
                cursor: pointer;
            }
        }
    }

    &__footer{
        border-top: 1px solid #DDDDDD;
        padding:10px 20px;
        gap:20px;

        &__left-column, &__right-column{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__left-column{

            &__dropdown{
                margin: 0px 10px;
            }
        }

        &__right-column{

            & > button{
                width: 36px;
                height: 36px;
                border-radius: 7px;
                background-color: #FFFFFF;
                border: 1px solid #DDDDDD;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & > button:hover{
                cursor: pointer;
            }

            &__center-button{
                margin: 0px 4px 0px 14px;

                & > img{
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &__order{
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #DDDDDD;

        &__selector, &__field{
            display: flex;
            align-items: center;
            border-right: 1px solid #DDDDDD;
            height: 100%;
        }

        &__field.last{
            border:none;
            width: calc(100% / 3);
        }

        &__field{
            width: calc(100% / 3);
            max-width: 325px;
        }
    }

    &__items-list{
        min-height: 400px;
    }

    &__loading{
        min-height: 478px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > div{
            transform: scale(2);
        }
    }
}