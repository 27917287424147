.home-page{
    position: relative;
    width: calc(55vw - 10px);
    max-width: 1080px;

    &__summary-section{
        margin-bottom: 5vh;
        gap:30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__chart{
        border-radius: 15px;
        border: 1px solid #DDDDDD;
        padding: 30px;
        position: relative;
    
        &__header{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 4%;
            justify-content: flex-start;
    
            &__title{
                font-size: 22px;
                color: #000000;
                line-height: 30px;
                text-transform: uppercase;
                font-weight: 600;
            }
    
            & > button{
                padding: 0px;
                margin: 0px;
                background-color: transparent;
                border: none;
                line-height: 24px;
                color: #9D9D9D;
                font-size: 18px;
                transition: all 0.3s ease-in-out;
            }
    
            & > button.active{
                color:#000000;
                font-weight: bold;
            }
    
            & > button:hover{
                cursor:pointer;
            }
        }

        &__info{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &__spin{
                margin: 30px 0px !important;
            }
        }
    }
}