.content-settings-contact-section{
    width: 100%;
    min-width: fit-content;
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    &__empty{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__content{
        width: 100%;
        &__header{
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 40px;
            & > p{
                font-weight: 800;
                font-size: 24px;
                color: #000000;
            }
        }
        & > p{
            font-weight: bold;
            font-size: 14px;
            color: #000000;
            margin-bottom: 5px;
            & > span{
                text-decoration: underline;
            }
        }
    }
}