.invites-filter-table-item{
    height: 80px;
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    color: #000000;
    font-size: 14px;
    position: relative;

    &__clicable{
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;

        &__left-field{
            width: 25%;
            max-width: 370px;

            &__user{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;

                &__avatar{
                    width: 40px;
                    height: 40px;
                    position: relative;
                    border-radius: 50%;
                    background-color: red;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    & > img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                & > div{

                    & > p{
                        display: block;
                        line-height: 20px;
                    }
                }
            }
        }

        &__right-field{
            width: 25%;
            gap: 20px;
            justify-content: space-between;

            &__edit-button, &__delete-button{
                position: absolute;
                background-color: transparent;
                border: none;
                padding: 0;
            }

            &__edit-button{
                right: 100px;
            }

            &__delete-button{
                right: 45px;
            }

            &__edit-button:hover, &__delete-button:hover{
                cursor: pointer;
            }
        }

        &__left-field, &__right-field{
            padding-left: 60px;
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;
    
            &__state{
                padding: 5px 15px;
                border-radius: 40px;
                font-weight: 600;
                font-size: 14px;
            }
    
            &__state.pending, &__state.concluded{
                line-height: 20px;
            }
    
            &__state.pending{
                background-color: #F4E57B;
                color: #CEB500;
            }
    
            &__state.concluded{
                background-color: #B6E388;
                color: #78C32C;
            }
    
            p{
                line-height: 14px;
                display: inline-block;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-right: 20px;
                max-width: 300px;
    
                & > img{
                    margin-right: 6px;
                    margin-top: -1px;
                }
            }

            @media only screen and (max-width: 1500px) {
                p{
                    max-width: 250px;
                }
            }

            @media only screen and (max-width: 1400px) {
                p{
                    max-width: 150px;
                }
            }
        }
    }
}