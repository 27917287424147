.image-modal{
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    width: fit-content !important;

    &__content{
        position: relative;

        & > img{
            min-height: 75vh;
            max-height: 90vh;
            object-fit: contain;
        }

        &__button{
            position: absolute;
            top: 0px;
            right: 0px;
            width: 40px;
            height: 40px;
            border-radius: 7px;
            margin: 10px 10px 0px 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0px 0px 24px rgb(0 0 0);
            border: none;
    
            & > svg{
                width: 30px;
                height: 30px;
    
                path{
                    fill:black;
                }
            }
        }
    
        &__button:hover{
            cursor: pointer;
        }
    }

    div.ant-modal-content{
        background-color: transparent;
        box-shadow: none;
        position: relative;
        width: fit-content !important;

        div.ant-modal-body{
            padding: 0px;
            max-height: fit-content !important;
            width: fit-content !important;
            display: block;
            margin: auto;
            position: relative;
        }

        button.ant-modal-close{
            display: none;
        }
    }
}

div.ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.5);
}