.user-popover{
    position: relative;

    &__button{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: white;
        margin: 20px auto;
        border: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 44px;
            height: 44px;
            object-fit: cover;
        }
    }

    &__button:hover{
        cursor: pointer;
    }

    &__bubble{
        position: absolute;
        bottom: 20px;
        left: 100%;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 11px rgb(0 0 0 / 30%);
        border-radius: 7px;
        z-index: 1;

        &__content{
            position: relative;

            &__tip{
                position: absolute;
                width: 15px;
                height: 15px;
                transform: rotate(45deg);
                border-radius: 3px;
                background-color: #FFFFFF;
                left: -6px;
                bottom: 15px;
                z-index: -2;
            }

            &__top-row{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                z-index: 2;
                padding: 20px 20px 15px 20px;
                border-bottom: 1px solid #DDDDDD;

                &__image{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: pink;
                    margin-right: 20px;
                }

                &__text{
                    color: #000000;

                    &__name{
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 12px;
                    }

                    &__email{
                        font-size: 12px;
                    }
                }
            }

            &__bottom-row{
                padding: 15px 20px 20px 20px;

                & img{
                    margin-right: 20px;
                    margin-left: 7px;
                }

                & > button{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 27px;
                    font-size: 14px;
                    color: #DDDDDD;
                    margin: 0px;
                    padding: 0px;
                    border: none;
                    background-color: transparent;

                    & > p{
                        margin-top: 2px;
                    }
                }

                & > button:hover{
                    cursor: pointer;
                }
            }
        }
    }
}